import { Caption1, Flex, H3, H4, H6 } from '@components/atoms'
import { ChartDivisionBox, IncreaseTextBox } from '@components/molecules'
import {
  CompanySnsFollowerChart,
  DailySnsFollowerTableChart,
  TracksSnsLikeTableChart,
} from '@components/organisms'
import React, { useEffect, useState } from 'react'
import { convertToPricingComma, dateYMD } from '@utils/format'
import {
  copyGrayImg,
  instagram_follower_img,
  melonSnsImg,
  soundcloud_follower_img,
  spotifySnsImg,
  twitter_follower_img,
  youtube_follower_img,
} from '@images/'

import DailySpotifyFollowerTableChart from '@components/organisms/Chart/DailySpotifyFollowerTableChart'
import DailyYoutubeFollowerTableChart from '@components/organisms/Chart/DailyYoutubeFollowerTableChart'
import TracksSpotifyLikeTableChart from '@components/organisms/Chart/TracksSpotifyLikeTableChart'
import TracksYoutubeLikeTableChart from '@components/organisms/Chart/TracksYoutubeLikeTableChart'
import { YoutubeListenTooltip } from '@components/organisms/Tooltip'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useStore } from '@utils/hooks'

const Content = styled(Flex)`
  /* width: '100%'; */
  /* height: '100%'; */
  flex-direction: column;
  padding: 50px 50px 50px 0;
`

const SectionTitle = styled(Flex)`
  flex-direction: column;
  text-align: left;
  width: 100%;

  margin-bottom: 30px;
`

const SnsInfo = styled(Flex)`
  align-items: center;
  height: 48px;
  margin: 0 0 30px 0;
  gap: 4px;

  img {
    width: 48px;
    height: 48px;
  }
`

const InsightCompanySnsTemplate = ({ artist, snsType }) => {
  const location = useLocation()

  const [periodType, setPeriodType] = useState({
    label: '지난 28일',
    value: 'monthly',
  })

  const { authStore, trendStore } = useStore()

  const fetchCompanyTrend = trendStore?.fetchCompanyTrend || (() => {})
  const fetchCompanyTrendTrack =
    trendStore?.fetchCompanyTrendTrack || (() => {})

  const { companyTrend } = trendStore
  const { currentUser } = authStore

  const likeCountInfo = companyTrend?.likeCountInfo || null
  const listenerInfo = companyTrend?.listenerInfo || null
  const followersInfo = companyTrend?.followersInfo || null
  const totalDailyList = companyTrend?.totalDailyList || []

  useEffect(() => {
    if (currentUser?.type === 'company' && currentUser?.companyId) {
      let __periodDays = 28
      if (periodType.value === 'daily') {
        __periodDays = 1
      }
      else if (periodType.value === 'weekly') {
        __periodDays = 7
      }
      else if (periodType.value === 'monthly') {
        __periodDays = 28
      }
      else if (periodType.value === '3months') {
        __periodDays = 90
      }
      else if (periodType.value === 'year') {
        __periodDays = 365
      }
      else if (periodType.value === 'all') {
        __periodDays = 365 * 10
      }

      fetchCompanyTrend(currentUser?.companyId, { __periodDays })
      fetchCompanyTrendTrack(currentUser?.companyId, { __periodDays })
    }
  }, [currentUser, periodType])

  return (
    <Flex type="column">
      <Content>
        <Flex style={{ justifyContent: 'space-between' }}>
          <Flex type="column">
            <SnsInfo>
              <img
                src={
                  snsType === 'melon'
                    ? melonSnsImg
                    : snsType === 'instagram'
                      ? instagram_follower_img
                      : snsType === 'youtube'
                        ? youtube_follower_img
                        : snsType === 'twitter'
                          ? twitter_follower_img
                          : snsType === 'soundcloud'
                            ? soundcloud_follower_img
                            : snsType === 'spotify'
                              ? spotifySnsImg
                              : false
                }
                alt="sns_img"
              />
              <H3
                align="left"
                style={
                  {
                    textTransform: 'capitalize',
                  }
                }
              >
                {snsType}
              </H3>
            </SnsInfo>
            <SectionTitle>
              <Flex
                style={{ marginBottom: 6, gap: 10, alignItems: 'flex-end' }}
              >
                <H6 align="left" color="#949494">
                  {`Total Daily`}
                </H6>
                <Caption1 align="left" color="#949494">
                  {
companyTrend?.trendDataAddedAt
  ? `*${dateYMD(companyTrend?.trendDataAddedAt)}`
  : ''
                  }
                </Caption1>
              </Flex>
              <Flex style={{ gap: '20px' }}>
                <Flex align="center">
                  <Flex
                    align="center"
                    gap="4px"
                    style={{ position: 'relative' }}
                  >
                    <H4 type="bold">
                      {
                        `${convertToPricingComma(listenerInfo?.today[snsType])
                        || 0} Listen`
                      }
                    </H4>
                    {snsType === 'youtube' && <YoutubeListenTooltip />}
                  </Flex>
                  <IncreaseTextBox
                    data={listenerInfo?.increaseValue[snsType] || 0}
                    style={{ marginLeft: '12px' }}
                  />
                </Flex>
                <Flex align="center">
                  <H4 type="bold">
                    {
                      `${convertToPricingComma(likeCountInfo?.today[snsType])
                      || 0} Like`
                    }
                  </H4>
                  <IncreaseTextBox
                    data={likeCountInfo?.increaseValue[snsType] || 0}
                    style={{ marginLeft: '12px' }}
                  />
                </Flex>
                {
                  convertToPricingComma(
                  followersInfo?.today && followersInfo?.today[snsType],
                  ) && (
                    <Flex align="center">
                      <H4 type="bold">
                        {
                          `${convertToPricingComma(
                        followersInfo?.today && followersInfo?.today[snsType],
                          ) || 0} Follower`
                        }
                      </H4>
                      <IncreaseTextBox
                        data={
                          (followersInfo?.increaseValue
                          && followersInfo?.increaseValue[snsType])
                        || 0
                        }
                        style={{ marginLeft: '12px' }}
                      />
                    </Flex>
                  )
                }
              </Flex>
            </SectionTitle>
          </Flex>
          <Flex align="center" style={{ gap: 30, height: 50 }}>
            {
              ((snsType === 'youtube' && artist?.youtubeUrl)
              || snsType !== 'youtube') && (
                <Flex
                  justify="center"
                  align="center"
                  style={
                    {
                      width: 28,
                      height: 28,
                      borderRadius: '50%',
                      backgroundColor: '#f4f4f4',
                      cursor: 'pointer',
                    }
                  }
                  onClick={
                    () => {
                      let url = window.document.location.host + location.pathname
                      switch (snsType) {
                        case 'melon':
                          url = artist.melonUrl
                          break
                        case 'instagram':
                          url = artist.instagramUrl
                          break
                        case 'youtube':
                          url = artist.youtubeUrl
                          break
                        case 'bugs':
                          url = artist.bugsUrl
                          break
                        case 'genie':
                          url = artist.genieUrl
                          break
                        case 'twitter':
                          url = artist.twitterUrl
                          break
                        case 'soundcloud':
                          url = artist.soundcloudUrl
                          break
                        case 'spotify':
                          url = artist.spotifyUrl
                          break
                        default:
                          break
                      }
                      window.open(url)
                    }
                  }
                >
                  <img src={copyGrayImg} width={20} height={20} alt="copy" />
                </Flex>
              )
            }
            <div style={{ width: '258px' }}>
              <ChartDivisionBox
                options={
                  [
                    { label: '전체', value: 'all' },
                    { label: '지난 1일', value: 'daily' },
                    { label: '지난 7일', value: 'weekly' },
                    { label: '지난 28일', value: 'monthly' },
                    { label: '지난 3개월', value: '3months' },
                    { label: '지난 1년', value: 'year' },
                  ]
                }
                value={periodType}
                onChange={value => setPeriodType(value)}
              />
            </div>
          </Flex>
        </Flex>
        <Flex style={{ gap: 20, alignItems: 'flex-start' }}>
          <CompanySnsFollowerChart
            dailyListData={totalDailyList}
            snsType={snsType}
            type="company"
          />
          {
            snsType === 'spotify' ? (
              <DailySpotifyFollowerTableChart
                data={totalDailyList}
                snsType="spotify"
                type="company"
              />
            ) : snsType === 'youtube' ? (
              <DailyYoutubeFollowerTableChart
                data={totalDailyList}
                snsType="youtube"
                type="company"
              />
            ) : (
              <DailySnsFollowerTableChart
                data={totalDailyList}
                snsType={snsType}
                type="company"
              />
            )
          }
        </Flex>
        {
          snsType === 'spotify' ? (
            <TracksSpotifyLikeTableChart snsType="spotify" type="company" />
          ) : snsType === 'youtube' ? (
            <TracksYoutubeLikeTableChart snsType="youtube" type="company" />
          ) : (
            <TracksSnsLikeTableChart snsType="melon" type="company" />
          )
        }
      </Content>
    </Flex>
  )
}

export default observer(InsightCompanySnsTemplate)
