import { Caption1, Caption2, Flex } from '@components/atoms'

import { IncreaseTextBox } from '@components/molecules'
import React from 'react'
import { Table } from 'antd'
import { convertToPricingComma } from '@utils/format'
import styled from 'styled-components'
import {
  SpotifyListenZeroTooltiop,
  SpotifyPopularityTooltip,
  YoutubeListenTooltip,
} from '../Tooltip'

const TableBox = styled.div`
  width: 100%;
  /* height: 936px; */
  height: fit-content;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #ffffff;
  padding: 50px;
  margin: 50px 0 20px 0;

  thead > tr > th {
    background-color: transparent;
    color: #646464;
  }

  thead > tr > th:nth-child(1),
  th:nth-child(2),
  th:nth-child(3),
  th:nth-child(4),
  th:nth-child(5) {
    text-align: center;
    min-width: 88px;
  }

  td {
    text-align: center;
  }

  .ant-table-header {
    overflow: visible !important;
  }
`

const DailyTrackLikeTableChart = ({ tableData }) => {
  const sortHandler = (a, b, sortOrder) => {
    if (a < b) {
      return -1
    }
    else if (a > b) {
      return 1
    }
    else {
      return 0
    }
  }

  const allSpotifyCountsAreZero = tableData.every(
    item => item.listenerCountInfo.spotify === 0,
  )

  return (
    <TableBox>
      <Table
        style={
          {
            width: '100%',
          }
        }
        dataSource={tableData?.reverse() || []}
        rowKey="date"
        pagination
        columns={
          [
            {
              title: '날짜',
              key: 'date',
              dataIndex: 'date',
              align: 'center',
              width: '120px',
              sorter: (a, b, sortOrder) => {
                return sortHandler(a?.date, b?.date)
              },
              render: text => {
                const strText = `${text}`
                return `${strText?.slice(0, 4)}년 ${strText?.slice(
                4,
                6,
              )}월 ${strText?.slice(6, 8)}일`
              },
            },
            {
              title: (
                <Flex type="column">
                  <Caption1 color="#646464">전체</Caption1>
                  <Caption2 color="#949494">Listen</Caption2>
                </Flex>
              ),
              key: 'listenerCountInfo.total',
              dataIndex: 'listenerCountInfo.total',
              align: 'center',
              sorter: (a, b, sortOrder) => {
                return sortHandler(
                a?.listenerCountInfo.total,
                b?.listenerCountInfo.total,
                )
              },
              render: (text, record) => {
                return (
                  <Flex type="column">
                    <Caption1>
                      {convertToPricingComma(record?.listenerCountInfo?.total)}
                    </Caption1>
                    <IncreaseTextBox
                      data={record?.listenerCountInfo?.increaseValue?.total}
                      style={{ alignSelf: 'center' }}
                    />
                  </Flex>
                )
              },
              width: '88px',
            },
            {
              title: (
                <Flex type="column">
                  <Caption1 color="#646464">멜론</Caption1>
                  <Caption2 color="#949494">Listener</Caption2>
                </Flex>
              ),
              key: 'listenerCountInfo.melon',
              dataIndex: 'listenerCountInfo.melon',
              align: 'center',
              sorter: (a, b, sortOrder) => {
                return sortHandler(
                a?.listenerCountInfo.melon,
                b?.listenerCountInfo.melon,
                )
              },
              render: (text, record) => {
                return (
                  <Flex type="column">
                    <Caption1>
                      {convertToPricingComma(record?.listenerCountInfo?.melon)}
                    </Caption1>
                    <IncreaseTextBox
                      data={record?.listenerCountInfo?.increaseValue?.melon}
                      style={{ alignSelf: 'center' }}
                    />
                  </Flex>
                )
              },
              width: '88px',
            },
            {
              title: (
                <Flex type="column">
                  <Caption1 color="#646464">유튜브</Caption1>
                  <Flex type="row" justify="center" align="center" gap="4px">
                    <Caption2 color="#949494">Listen</Caption2>
                    <YoutubeListenTooltip />
                  </Flex>
                </Flex>
              ),
              key: 'listenerCountInfo.youtube',
              dataIndex: 'listenerCountInfo.youtube',
              align: 'center',
              sorter: (a, b, sortOrder) => {
                return sortHandler(
                a?.listenerCountInfo.youtube,
                b?.listenerCountInfo.youtube,
                )
              },
              render: (text, record) => {
                return (
                  <Flex type="column">
                    <Caption1>
                      {convertToPricingComma(record?.listenerCountInfo?.youtube)}
                    </Caption1>
                    <IncreaseTextBox
                      data={record?.listenerCountInfo?.increaseValue?.youtube}
                      style={{ alignSelf: 'center' }}
                    />
                  </Flex>
                )
              },
              width: '90px',
            },
            // {
            //   title: (
            //     <Flex type="column">
            //       <Caption1 color="#646464">스포티파이</Caption1>
            //       <Caption2 color="#949494">Listen</Caption2>
            //     </Flex>
            //   ),
            //   key: 'listenerCountInfo.spotify',
            //   dataIndex: 'listenerCountInfo.spotify',
            //   align: 'center',
            //   sorter: (a, b, sortOrder) => {
            //     return sortHandler(
            //     a?.listenerCountInfo.spotify,
            //     b?.listenerCountInfo.spotify,
            //     )
            //   },
            //   render: (text, record) => {
            //     return (
            //       <Flex type="column" style={{ alignItems: 'center' }}>
            //         <Caption1>
            //           {
            //             allSpotifyCountsAreZero
            //               ? '1000 미만'
            //               : convertToPricingComma(
            //               record?.listenerCountInfo?.spotify,
            //               )
            //           }
            //         </Caption1>
            //         {
            //           allSpotifyCountsAreZero ? (
            //             <SpotifyListenZeroTooltiop />
            //           ) : (
            //             <IncreaseTextBox
            //               data={record?.listenerCountInfo?.increaseValue?.spotify}
            //               style={{ alignSelf: 'center' }}
            //             />
            //           )
            //         }
            //       </Flex>
            //     )
            //   },
            //   width: '100px',
            // },
            {
              title: (
                <Flex type="column">
                  <Caption1 color="#646464">멜론</Caption1>
                  <Caption2 color="#949494">Rank</Caption2>
                </Flex>
              ),
              key: 'rankInfo.melon',
              dataIndex: 'rankInfo.melon',
              align: 'center',
              sorter: (a, b, sortOrder) => {
                return sortHandler(a?.rankInfo.melon, b?.rankInfo.melon)
              },
              render: (text, record) => {
                return (
                  <Flex type="column">
                    <Caption1>
                      {convertToPricingComma(record?.rankInfo?.melon)}
                    </Caption1>

                    <IncreaseTextBox
                      data={record?.rankInfo?.increaseValue?.melon}
                      style={{ alignSelf: 'center' }}
                    />
                  </Flex>
                )
              },
              width: '88px',
            },
            {
              title: (
                <Flex type="column">
                  <Caption1 color="#646464">스포티파이</Caption1>
                  <Flex type="row" justify="center" align="center" gap="4px">
                    <Caption2 color="#949494">Popularity</Caption2>
                    <SpotifyPopularityTooltip />
                  </Flex>
                </Flex>
              ),
              key: 'rankInfo.spotify',
              dataIndex: 'rankInfo.spotify',
              align: 'center',
              sorter: (a, b, sortOrder) => {
                return sortHandler(a?.rankInfo.spotify, b?.rankInfo.spotify)
              },
              render: (text, record) => {
                return (
                  <Flex type="column">
                    <Caption1>
                      {convertToPricingComma(record?.rankInfo?.spotify)}
                    </Caption1>

                    <IncreaseTextBox
                      data={record?.rankInfo?.increaseValue?.spotify}
                      style={{ alignSelf: 'center' }}
                    />
                  </Flex>
                )
              },
              width: '100px',
            },
            {
              title: (
                <Flex type="column">
                  <Caption1 color="#646464">전체</Caption1>
                  <Caption2 color="#949494">Like</Caption2>
                </Flex>
              ),
              key: 'likeCountInfo.total',
              dataIndex: 'likeCountInfo.total',
              align: 'center',
              sorter: (a, b, sortOrder) => {
                return sortHandler(
                a?.likeCountInfo?.total,
                b?.likeCountInfo?.total,
                )
              },
              render: (text, record) => {
                return (
                  <Flex type="column">
                    <Caption1>
                      {convertToPricingComma(record?.likeCountInfo?.total)}
                    </Caption1>
                    <IncreaseTextBox
                      data={record?.likeCountInfo?.increaseValue?.total}
                      style={{ alignSelf: 'center' }}
                    />
                  </Flex>
                )
              },
              width: '88px',
            },
            {
              title: (
                <Flex type="column">
                  <Caption1 color="#646464">멜론</Caption1>
                  <Caption2 color="#949494">Like</Caption2>
                </Flex>
              ),
              key: 'likeCountInfo.melon',
              dataIndex: 'likeCountInfo.melon',
              align: 'center',
              sorter: (a, b, sortOrder) => {
                return sortHandler(a?.likeCountInfo.melon, b?.likeCountInfo.melon)
              },
              render: (text, record) => {
                return (
                  <Flex type="column">
                    <Caption1>
                      {convertToPricingComma(record?.likeCountInfo?.melon)}
                    </Caption1>
                    <IncreaseTextBox
                      data={record?.likeCountInfo?.increaseValue?.melon}
                      style={{ alignSelf: 'center' }}
                    />
                  </Flex>
                )
              },
              width: '88px',
            },
            {
              title: (
                <Flex type="column">
                  <Caption1 color="#646464">유튜브</Caption1>
                  <Caption2 color="#949494">Like</Caption2>
                </Flex>
              ),
              key: 'likeCountInfo.youtube',
              dataIndex: 'likeCountInfo.youtube',
              align: 'center',
              sorter: (a, b, sortOrder) => {
                return sortHandler(
                a?.likeCountInfo.youtube,
                b?.likeCountInfo.youtube,
                )
              },
              render: (text, record) => {
                return (
                  <Flex type="column">
                    <Caption1>
                      {convertToPricingComma(record?.likeCountInfo?.youtube)}
                    </Caption1>
                    <IncreaseTextBox
                      data={record?.likeCountInfo?.increaseValue?.youtube}
                      style={{ alignSelf: 'center' }}
                    />
                  </Flex>
                )
              },
              width: '90px',
            },
            {
              title: (
                <Flex type="column">
                  <Caption1 color="#646464">지니 & 벅스</Caption1>
                  <Caption2 color="#949494">Like</Caption2>
                </Flex>
              ),
              key: 'likeCountInfo.bugs',
              dataIndex: 'likeCountInfo.bugs',
              align: 'center',
              sorter: (a, b, sortOrder) => {
                return sortHandler(
                a?.likeCountInfo.bugs + a?.likeCountInfo.genie,
                b?.likeCountInfo.bugs + b?.likeCountInfo.genie,
                )
              },
              render: (text, record) => {
                return (
                  <Flex type="column">
                    <Caption1>
                      {
                        convertToPricingComma(
                      record?.likeCountInfo?.bugs
                        + record?.likeCountInfo?.genie,
                        )
                      }
                    </Caption1>
                    <IncreaseTextBox
                      data={
                      record?.likeCountInfo?.increaseValue?.bugs
                      + record?.likeCountInfo?.genie
                      }
                      style={{ alignSelf: 'center' }}
                    />
                  </Flex>
                )
              },
              width: '105px',
            },
          ]
        }
      />
    </TableBox>
  )
}

export default DailyTrackLikeTableChart
