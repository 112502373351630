import { Flex, H6, Caption2, Div, Button, Caption1 } from '@components/atoms'
import React from 'react'
import { colors } from '@colors/'
import { STORAGE_URL } from '@consts/'
import styled from 'styled-components'
import ReactLoading from 'react-loading'

const MinusBtn = ({ onClick }) => {
  return (
    <Flex
      onClick={onClick}
      style={
        {
          position: 'absolute',
          top: -17,
          right: -26,
          width: 35,
          height: 35,
          borderRadius: 17,
          backgroundColor: '#ea4653',
          justifyContent: 'center',
          alignItems: 'center',
        }
      }
    >
      <Flex style={{ width: 15, height: 2.5, backgroundColor: '#ffffff' }} />
    </Flex>
  )
}

const ConnectionArtistCard = ({
  item,
  onClick,
  isUpdate,
  tempDeleteCompanyIdList,
  setArtistViewMode,
}) => {
  return (
    <Flex
      onClick={
        () => {
          if (item && item.isAccepted && !isUpdate) {
            onClick(item?.artistInfo?._id)
          }
        }
      }
      style={
        {
          width: 290,
          height: 403,
          cursor: 'pointer',
          position: 'relative',
        }
      }
      type="column"
      align="center"
      justify="flex-start"
    >
      {
        item && item.isRejected && (
          <Flex
            style={
              {
                width: '100%',
                height: '100%',
                position: 'absolute',
                flexDirection: 'column',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                borderRadius: 8,
                justifyContent: 'center',
                alignItems: 'center',
              }
            }
          >
            <H6 type="Bold" color={colors.white} style={{ marginTop: 16 }}>
            아티스트가
              <br />
            요청을 거부했습니다.
            </H6>
          </Flex>
        )
      }
      {
        item && item.isProgress && (
          <Flex
            style={
              {
                width: '100%',
                height: '100%',
                position: 'absolute',
                flexDirection: 'column',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                borderRadius: 8,
                justifyContent: 'center',
                alignItems: 'center',
              }
            }
          >
            <ReactLoading
              type="spinningBubbles"
              color="#ffffff"
              height={32}
              width={32}
            />
            <H6 type="Bold" color={colors.white} style={{ marginTop: 16 }}>
            승인 대기중...
            </H6>
          </Flex>
        )
      }
      {
        isUpdate && (
          <MinusBtn
            onClick={
              e => {
                e.preventDefault()
                e.stopPropagation()
                tempDeleteCompanyIdList(item._id)
              }
            }
          />
        )
      }
      {
item?.artistInfo?.image512Path ? (
  <img
    src={
      item
            && item.artistInfo
            && item.artistInfo.image512Path
            && STORAGE_URL + item.artistInfo.image512Path
    }
    alt="cover_img"
    width="290"
    height="290"
    style={
      {
        border: '1px solid #eaeaea',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
      }
    }
  />
) : (
  <Div width="290px" height="290px" />
)
      }
      <Flex
        align="center"
        justify="center"
        type={'column'}
        style={
          {
            width: '100%',
            height: '100%',
            border: 'solid 1px #eaeaea',
            boxSizing: 'border-box',
            borderTop: 'none',
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            paddingTop: '16px',
            paddingBottom: '12px',
          }
        }
      >
        <Flex>
          <TypeText type="Bold">
            {(item && item.artistInfo && item.artistInfo.type) || '개인'}
          </TypeText>
          <H6>
            {(item && item.artistInfo && item.artistInfo.name) || '가수명'}
          </H6>
        </Flex>
        <Flex style={{ marginTop: '20px', gap: '12px' }}>
          <MoveButton>
            <Caption1>정산서</Caption1>
          </MoveButton>
          <MoveButton
            onClick={
              async event => {
                event.stopPropagation()
                event.preventDefault()

                await setArtistViewMode(item?.artistInfo?._id)

                window.open(`/artist?artistId=${item?.artistInfo?._id}`)
              }
            }
          >
            <Caption1>아티스트</Caption1>
          </MoveButton>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ConnectionArtistCard

const TypeText = styled(Caption2)`
  width: 40px;
  height: 24px;
  margin-right: 8px;
  padding: 3px;

  background-color: ${colors.black};
  color: ${colors.white};
  border-radius: 4px;
`

const MoveButton = styled(Button)`
  display: flex;
  padding: 8px 10px;
  width: 124px;
  height: 40px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 4px;
  border: 1px solid var(--grey-grey_80, #242424);
  background: var(--bg_white, #fff);
  box-sizing: border-box;
`
