import { Caption1, Caption2, Flex, H3, Input } from '@components/atoms'
import React, { useCallback, useEffect, useState } from 'react'

import { IncreaseTextBox } from '@components/molecules'
import { STORAGE_URL } from '@consts/'
import { Table } from 'antd'
import { convertToPricingComma } from '@utils/format'
import { observer } from 'mobx-react'
import { search_img } from '@images/'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useStore } from '@utils/hooks'
import {
  SpotifyListenZeroTooltiop,
  SpotifyPopularityTooltip,
  YoutubeListenTooltip,
} from '../Tooltip'

const TableBox = styled.div`
  width: 100%;
  height: 936px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #ffffff;
  padding: 8px;

  tr {
    cursor: pointer;
  }

  thead > tr > th {
    background-color: transparent;
    color: #646464;
  }

  thead > tr > th:nth-child(3),
  th:nth-child(4),
  th:nth-child(5),
  th:nth-child(6),
  th:nth-child(7),
  th:nth-child(8) {
    text-align: center;
    min-width: 88px;
  }

  .ant-table-header {
    overflow: visible !important;
  }
`

const sortTableData = (
  key: 'release' | 'popular' | 'create' | 'name',
  tableData,
) => {
  switch (key) {
    case 'release':
      return tableData.sort((a, b) => {
        return a?.albumInfo?.releasedAt > b?.albumInfo?.releasedAt
          ? -1
          : a?.albumInfo?.releasedAt < b?.albumInfo?.releasedAt
            ? 1
            : 0
      })
    case 'create':
      return tableData.sort((a, b) => {
        return a?.createdAt > b?.createdAt
          ? -1
          : a?.createdAt < b?.createdAt
            ? 1
            : 0
      })
    case 'name':
      return tableData.sort((a, b) => {
        return a.title < b.title ? -1 : a.title < b.title ? 1 : 0
      })
    case 'popular':
      return tableData.sort((a, b) => {
        return a?.likeCountInfo?.total > b?.likeCountInfo?.total
          ? -1
          : a?.likeCountInfo?.total < b?.likeCountInfo?.total
            ? 1
            : 0
      })
    default:
      return tableData
  }
}

const TracksLikeTableChart = ({
  type = 'artist',
  onInsightTypeChange = null,
}) => {
  const { trendStore } = useStore()

  const { artistTrendTrackList, companyTrendTrackList } = trendStore
  const [tableData, setTableData] = useState([])
  const [sort, setSort] = useState('popular')

  const history = useHistory()

  const sortHandler = (a, b, sortOrder) => {
    if (a < b) {
      return -1
    }
    else if (a > b) {
      return 1
    }
    else {
      return 0
    }
  }

  const handeSortClick = useCallback(
    (key: 'release' | 'popular' | 'create' | 'name') => {
      setSort(key)
      setTableData(prev => sortTableData(key, prev))
    },
    [setTableData, setSort],
  )

  useEffect(() => {
    // setTableData(
    //   sortTableData(
    //     sort,
    //     type === 'company' ? companyTrendTrackList : artistTrendTrackList,
    //   ),
    // )
    setTableData(
      type === 'company' ? companyTrendTrackList : artistTrendTrackList,
    )
  }, [type, artistTrendTrackList, companyTrendTrackList])

  return (
    <Flex type="column" style={{ marginTop: '50px' }}>
      <Flex style={{ justifyContent: 'space-between' }}>
        <H3 align="left">뮤직</H3>
      </Flex>
      <Flex style={{ margin: '30px 0', justifyContent: 'space-between' }}>
        <Flex
          style={
            {
              width: '410px',
              borderRadius: '2px',
              border: '1px solid #949494',
              padding: '8px 12px',
            }
          }
        >
          <img
            src={search_img}
            alt="search_img"
            style={{ width: '24px', height: '24px' }}
          />
          <Input
            style={
              {
                width: '362px',
                height: '24px',
                border: 'none',
              }
            }
            placeholder="검색어를 입력해주세요"
            onChange={
              e => {
                if (type === 'company') {
                  setTableData(
                    sortTableData(
                      sort,
                      companyTrendTrackList.filter(item =>
                        item.title
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase()),
                      ),
                    ),
                  )
                }
                else {
                  setTableData(
                    sortTableData(
                      sort,
                      artistTrendTrackList.filter(item =>
                        item.title
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase()),
                      ),
                    ),
                  )
                }
              }
            }
          />
        </Flex>
        {/* <Flex style={{ width: '258px', gap: 20 }}>
          <Caption1
            color={sort === 'release' ? 'black' : '#949494'}
            onClick={() => handeSortClick('release')}
            style={
              {
                cursor: 'pointer',
              }
            }
          >
            발매일순
          </Caption1>
          <Caption1
            color={sort === 'popular' ? 'black' : '#949494'}
            onClick={() => handeSortClick('popular')}
            style={{ cursor: 'pointer' }}
          >
            인기순
          </Caption1>
          <Caption1
            color={sort === 'create' ? 'black' : '#949494'}
            onClick={() => handeSortClick('create')}
            style={{ cursor: 'pointer' }}
          >
            등록순
          </Caption1>
          <Caption1
            color={sort === 'name' ? 'black' : '#949494'}
            onClick={() => handeSortClick('name')}
            style={{ cursor: 'pointer' }}
          >
            이름순
          </Caption1>
        </Flex> */}
      </Flex>

      <TableBox>
        <Table
          className="tracks_like_table"
          pagination={false}
          rowKey="_id"
          dataSource={tableData.slice() || []}
          style={
            {
              width: '100%',
            }
          }
          scroll={{ y: 800 }}
          onRow={
            record => {
              return {
                onClick: () => {
                  if (onInsightTypeChange) {
                    onInsightTypeChange(`트랙${record?._id}`)
                  }
                  else {
                    history.push(`/insight/artist/track/${record?._id}`)
                  }
                },
              }
            }
          }
          columns={
            [
              {
                title: '#',
                key: '_id',
                dataIndex: '_id',
                align: 'center',
                render: (record, text, index) => {
                  return index + 1
                },
                width: '90px',
              },
              {
                title: '트랙명',
                key: 'title',
                dataIndex: 'title',
                align: 'left',
                sorter: (a, b, sortOrder) => {
                  return sortHandler(a?.title, b?.title)
                },
                render: (text, record) => {
                  return (
                    <Flex>
                      <img
                        src={STORAGE_URL + record?.albumInfo?.image64Path}
                        alt="cover_img"
                        style={
                          {
                            width: '36px',
                            height: '36px',
                            borderRadius: '6px',
                            marginRight: '16px',
                          }
                        }
                      />
                      <Flex type="column">
                        <Caption2
                          style={
                            {
                              fontSize: 10,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '1',
                              WebkitBoxOrient: 'vertical',
                            }
                          }
                          align="left"
                          color="#949494"
                        >
                          {record?.albumInfo?.title}
                        </Caption2>
                        <Caption2
                          style={
                            {
                              fontSize: 12,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '1',
                              WebkitBoxOrient: 'vertical',
                            }
                          }
                          align="left"
                        >
                          {text}
                        </Caption2>
                      </Flex>
                    </Flex>
                  )
                },
                width: '300px',
              },
              {
                title: (
                  <Flex type="column">
                    <Caption1 color="#646464">전체</Caption1>
                    <Caption2 color="#949494">Listen</Caption2>
                  </Flex>
                ),
                key: 'listenerCountInfo.total',
                dataIndex: 'listenerCountInfo.total',
                align: 'center',
                sorter: (a, b, sortOrder) => {
                  return sortHandler(
                  a?.listenerCountInfo?.total,
                  b?.listenerCountInfo?.total,
                  )
                },
                render: (text, record) => {
                  return (
                    <Flex type="column">
                      <Caption1>
                        {convertToPricingComma(record?.listenerCountInfo?.total)}
                      </Caption1>
                      <IncreaseTextBox
                        data={record?.listenerCountInfo?.increaseValue?.total}
                        style={{ alignSelf: 'center' }}
                      />
                    </Flex>
                  )
                },
                width: '88px',
              },
              {
                title: (
                  <Flex type="column">
                    <Caption1 color="#646464">멜론</Caption1>
                    <Caption2 color="#949494">Listener</Caption2>
                  </Flex>
                ),
                key: 'listenerCountInfo.melon',
                dataIndex: 'listenerCountInfo.melon',
                align: 'center',
                sorter: (a, b, sortOrder) => {
                  return sortHandler(
                  a?.listenerCountInfo?.melon,
                  b?.listenerCountInfo?.melon,
                  )
                },
                render: (text, record) => {
                  return (
                    <Flex type="column">
                      <Caption1>
                        {convertToPricingComma(record?.listenerCountInfo?.melon)}
                      </Caption1>
                      <IncreaseTextBox
                        data={record?.listenerCountInfo?.increaseValue?.melon}
                        style={{ alignSelf: 'center' }}
                      />
                    </Flex>
                  )
                },
                width: '88px',
              },
              {
                title: (
                  <Flex type="column">
                    <Caption1 color="#646464">유튜브</Caption1>
                    <Flex type="row" justify="center" align="center" gap="4px">
                      <Caption2 color="#949494">Listen</Caption2>
                      <YoutubeListenTooltip />
                    </Flex>
                  </Flex>
                ),
                key: 'listenerCountInfo.youtube',
                dataIndex: 'listenerCountInfo.youtube',
                align: 'center',
                sorter: (a, b, sortOrder) => {
                  return sortHandler(
                  a?.listenerCountInfo?.youtube,
                  b?.listenerCountInfo?.youtube,
                  )
                },
                render: (text, record) => {
                  return (
                    <Flex type="column">
                      <Caption1>
                        {
                          convertToPricingComma(
                        record?.listenerCountInfo?.youtube,
                          )
                        }
                      </Caption1>
                      <IncreaseTextBox
                        data={record?.listenerCountInfo?.increaseValue?.youtube}
                        style={{ alignSelf: 'center' }}
                      />
                    </Flex>
                  )
                },
                width: '90px',
              },
              //               {
              //                 title: (
              //                   <Flex type="column">
              //                     <Caption1 color="#646464">스포티파이</Caption1>
              //                     <Caption2 color="#949494">Listen</Caption2>
              //                   </Flex>
              //                 ),
              //                 key: 'listenerCountInfo.spotify',
              //                 dataIndex: 'listenerCountInfo.spotify',
              //                 align: 'center',
              //                 sorter: (a, b, sortOrder) => {
              //                   return sortHandler(
              //                   a?.listenerCountInfo?.spotify,
              //                   b?.listenerCountInfo?.spotify,
              //                   )
              //                 },
              //                 render: (text, record) => {
              //                   return (
              //                     <Flex type="column" style={{ alignItems: 'center' }}>
              //                       <Caption1>
              //                         {
              // record?.listenerCountInfo?.spotify === 0
              //   ? '1000 미만'
              //   : convertToPricingComma(
              //                             record?.listenerCountInfo?.spotify,
              //   )
              //                         }
              //                       </Caption1>
              //                       {
              // record?.listenerCountInfo?.spotify === 0 ? (
              //   <SpotifyListenZeroTooltiop />
              // ) : (
              //   <IncreaseTextBox
              //     data={record?.listenerCountInfo?.increaseValue?.spotify}
              //     style={{ alignSelf: 'center' }}
              //   />
              // )
              //                       }
              //                     </Flex>
              //                   )
              //                 },
              //                 width: '100px',
              //               },
              {
                title: (
                  <Flex type="column">
                    <Caption1 color="#646464">멜론</Caption1>
                    <Caption2 color="#949494">Rank</Caption2>
                  </Flex>
                ),
                key: 'rankInfo.melon',
                dataIndex: 'rankInfo.melon',
                align: 'center',
                sorter: (a, b, sortOrder) => {
                  return sortHandler(a?.rankInfo.melon, b?.rankInfo.melon)
                },
                render: (text, record) => {
                  return (
                    <Flex type="column">
                      <Caption1>
                        {convertToPricingComma(record?.rankInfo?.melon)}
                      </Caption1>

                      <IncreaseTextBox
                        data={record?.rankInfo?.increaseValue?.melon}
                        style={{ alignSelf: 'center' }}
                      />
                    </Flex>
                  )
                },
                width: '88px',
              },
              {
                title: (
                  <Flex type="column">
                    <Caption1 color="#646464">스포티파이</Caption1>
                    <Flex type="row" justify="center" align="center" gap="4px">
                      <Caption2 color="#949494">Popularity</Caption2>
                      <SpotifyPopularityTooltip />
                    </Flex>
                  </Flex>
                ),
                key: 'rankInfo.spotify',
                dataIndex: 'rankInfo.spotify',
                align: 'center',
                sorter: (a, b, sortOrder) => {
                  return sortHandler(a?.rankInfo.spotify, b?.rankInfo.spotify)
                },
                render: (text, record) => {
                  return (
                    <Flex type="column">
                      <Caption1>
                        {convertToPricingComma(record?.rankInfo?.spotify)}
                      </Caption1>

                      <IncreaseTextBox
                        data={record?.rankInfo?.increaseValue?.spotify}
                        style={{ alignSelf: 'center' }}
                      />
                    </Flex>
                  )
                },
                width: '100px',
              },
              {
                title: (
                  <Flex type="column">
                    <Caption1 color="#646464">전체</Caption1>
                    <Caption2 color="#949494">Like</Caption2>
                  </Flex>
                ),
                key: 'likeCountInfo.total',
                dataIndex: 'likeCountInfo.total',
                align: 'center',
                sorter: (a, b, sortOrder) => {
                  return sortHandler(
                  a?.likeCountInfo.total,
                  b?.likeCountInfo.total,
                  )
                },
                render: (text, record) => {
                  return (
                    <Flex type="column">
                      <Caption1>
                        {convertToPricingComma(record?.likeCountInfo?.total)}
                      </Caption1>
                      <IncreaseTextBox
                        data={record?.likeCountInfo?.increaseValue?.total}
                        style={{ alignSelf: 'center' }}
                      />
                    </Flex>
                  )
                },
                width: '88px',
              },
              {
                title: (
                  <Flex type="column">
                    <Caption1 color="#646464">멜론</Caption1>
                    <Caption2 color="#949494">Like</Caption2>
                  </Flex>
                ),
                key: 'likeCountInfo.melon',
                dataIndex: 'likeCountInfo.melon',
                align: 'center',
                sorter: (a, b, sortOrder) => {
                  return sortHandler(
                  a?.likeCountInfo.melon,
                  b?.likeCountInfo.melon,
                  )
                },
                render: (text, record) => {
                  return (
                    <Flex type="column">
                      <Caption1>
                        {convertToPricingComma(record?.likeCountInfo?.melon)}
                      </Caption1>
                      <IncreaseTextBox
                        data={record?.likeCountInfo?.increaseValue?.melon}
                        style={{ alignSelf: 'center' }}
                      />
                    </Flex>
                  )
                },
                width: '88px',
              },
              {
                title: (
                  <Flex type="column">
                    <Caption1 color="#646464">유튜브</Caption1>
                    <Caption2 color="#949494">Like</Caption2>
                  </Flex>
                ),
                key: 'likeCountInfo.youtube',
                dataIndex: 'likeCountInfo.youtube',
                align: 'center',
                sorter: (a, b, sortOrder) => {
                  return sortHandler(
                  a?.likeCountInfo.youtube,
                  b?.likeCountInfo.youtube,
                  )
                },
                render: (text, record) => {
                  return (
                    <Flex type="column">
                      <Caption1>
                        {convertToPricingComma(record?.likeCountInfo?.youtube)}
                      </Caption1>
                      <IncreaseTextBox
                        data={record?.likeCountInfo?.increaseValue?.youtube}
                        style={{ alignSelf: 'center' }}
                      />
                    </Flex>
                  )
                },
                width: '90px',
              },
              {
                title: (
                  <Flex type="column">
                    <Caption1 color="#646464">지니 & 벅스</Caption1>
                    <Caption2 color="#949494">Like</Caption2>
                  </Flex>
                ),
                key: 'likeCountInfo.bugs',
                dataIndex: 'likeCountInfo.bugs',
                align: 'center',
                sorter: (a, b, sortOrder) => {
                  return sortHandler(a?.likeCountInfo.bugs, b?.likeCountInfo.bugs)
                },
                render: (text, record) => {
                  return (
                    <Flex type="column">
                      <Caption1>
                        {
                          convertToPricingComma(
                        record?.likeCountInfo?.bugs
                          + record?.likeCountInfo?.genie,
                          )
                        }
                      </Caption1>
                      <IncreaseTextBox
                        data={
                        record?.likeCountInfo?.increaseValue?.bugs
                        + record?.likeCountInfo?.increaseValue?.genie
                        }
                        style={{ alignSelf: 'center' }}
                      />
                    </Flex>
                  )
                },
                width: '100px',
              },
            ]
          }
        />
      </TableBox>
    </Flex>
  )
}

export default observer(TracksLikeTableChart)
