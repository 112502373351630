/* eslint-disable import/no-unresolved */
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Flex, Image, H6 } from '@components/atoms'
import { fullHD } from '@styles/media'
import { Link, useHistory } from 'react-router-dom'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import { STORAGE_URL } from '@consts'
import { alarmIcon, companyDefaultLogoImg } from '@images/index'
import RegisterCompanyFormModal from '../Modal/RegisterCompanyFormModal'

const HeaderContainer = styled(Flex)`
  width: 100%;
  height: 80px;
  border-bottom: solid 0.5px #242424;
  justify-content: space-between;

  @media ${fullHD} {
    width: 100%;
  }

  .Logo {
    margin: 0px 0px 0px 50px;
    align-self: center;
  }

  /* .UserImage {
    margin: 16px 50px 0px 0px;
  } */
`
const Logo = styled(Image).attrs(props => {
  return {
    src: props.isCompany
      ? require('@images/plam_logo_company.png')
      : require('@images/plam_logo_artist.png'),
    className: 'Logo',
  }
})`
  width: ${props => (props.isCompany ? '176.8px' : '156px')};
  height: ${props => (props.isCompany ? '26px' : '26px')};
  cursor: pointer;
`

const UserImage = styled(Image)`
  width: 48px;
  height: 48px;
  border-radius: 24px;
`

const SubMenu = styled.div`
  text-align: center;
  position: absolute;
  top: 40px;
  right: 30px;
  z-index: 10;
  width: 150px;
  height: ${({ isCompany }) => (isCompany ? '96px' : '64px')};
  border-radius: 6px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
`

const MenuList = styled.div`
  padding: 10px 16px;
  cursor: pointer;
  width: 152px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #242424;
`

const Header = ({ logout }) => {
  const { toggle, isShowing } = RegisterCompanyFormModal.useModal()
  const { authStore, artistStore } = useStore()
  const [showMenu, setShowMenu] = useState(false)

  const { currentUser } = authStore
  const { artistDetail } = artistStore

  const fetchArtistDetail = artistStore?.fetchArtistDetail || (() => {})

  const history = useHistory()

  const imageUri = useMemo(() => {
    if (currentUser?.type === 'company') {
      const isArtistPage = !!currentUser?.isArtistViewMode
      return (
        (isArtistPage
          && artistDetail?.image128Path
          && STORAGE_URL + artistDetail?.image128Path)
        || (currentUser?.profileImage128Path
          ? STORAGE_URL + currentUser?.profileImage128Path
          : companyDefaultLogoImg)
        || {}
      )
    }
    else {
      return (
        (artistDetail?.image128Path
          && STORAGE_URL + artistDetail?.image128Path)
        || {}
      )
    }
  }, [currentUser, currentUser?.isArtistViewMode, artistDetail])

  useEffect(() => {
    if (
      currentUser?.type !== 'company'
      && currentUser?.artistId
      && !artistDetail
    ) {
      fetchArtistDetail(currentUser?.artistId)
    }
    else if (currentUser?.type === 'company' && currentUser?.companyId) {
      //
    }
  }, [currentUser, artistDetail, fetchArtistDetail])

  return (
    <HeaderContainer>
      <Flex>
        <Logo
          isCompany={currentUser?.type === 'company'}
          onClick={
            () => {
              history.push('/')
            }
          }
        />
      </Flex>
      <Flex>
        <Flex
          align={'center'}
          style={
            {
              marginTop: '16px',
              marginRight: '16px',
              cursor: 'pointer',
              position: 'relative',
            }
          }
        >
          <Link to={`/notification`} style={{ position: 'relative' }}>
            <img
              src={alarmIcon}
              alt="alarm_icon"
              style={
                {
                  width: '24px',
                  height: '24px',
                }
              }
            />
            {
currentUser?.isNewAlertNotifications && (
                <Flex
                  style={
                    {
                      width: '6px',
                      height: '6px',
                      borderRadius: '6px',
                      background: '#EA4653',
                      position: 'absolute',
                      top: 2,
                      right: 4,
                    }
                  }
                />
              )
            }
          </Link>
        </Flex>
        <Flex
          align="center"
          style={
            {
              position: 'relative',
              margin: '16px 50px 0px 0px',
            }
          }
          onClick={() => setShowMenu(true)}
          onMouseOver={() => setShowMenu(true)}
          onMouseLeave={() => setShowMenu(false)}
        >
          {
            currentUser
            && (currentUser.type === 'company' ? (
              <H6 style={{ marginRight: 16 }}>
                {currentUser?.companyName || ''}
              </H6>
            ) : (
              <H6 style={{ marginRight: 16 }}>
                {currentUser?.artistInfo?.name || ''}
              </H6>
            ))
          }
          <UserImage src={imageUri} className="UserImage" />
          {
            showMenu && (
              <SubMenu isCompany={currentUser?.type === 'company'}>
                {
currentUser?.type === 'company' && (
                    <MenuList onClick={toggle}>사업자 정보 수정</MenuList>
                  )
                }
                <MenuList onClick={() => history.push('/change/password')}>
                비밀번호 변경
                </MenuList>
                <MenuList onClick={() => logout()}>로그아웃</MenuList>
              </SubMenu>
            )
          }
        </Flex>
      </Flex>
      {
currentUser?.type === 'company' && (
          <RegisterCompanyFormModal.View toggle={toggle} isShowing={isShowing} />
        )
      }
    </HeaderContainer>
  )
}

export default observer(Header)
