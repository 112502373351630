/* eslint-disable import/no-unresolved */

import { Button, Flex, H3, H6, Image, Input } from '@components/atoms'
import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { colors } from '@colors/'

import { add_btn_gray, modify_img } from '@images/'
import {
  ConnectCompanyArtistModal,
  ConnectionArtistCard,
} from '@components/organisms'

const SearchArtistInput = styled(Input)`
  width: 410px;
  height: 40px;
  margin-bottom: 30px;
  border-color: #949494;
`

const AddArtistCard = ({ toggle }) => {
  return (
    <Flex
      onClick={toggle}
      style={
        {
          width: 290,
          height: 403,
          border: 'dashed 2px #d4d4d4',
          cursor: 'pointer',
        }
      }
      align="center"
      justify="center"
    >
      <Flex type="column" align="center">
        <Flex
          align="center"
          justify="center"
          style={
            {
              width: 56,
              height: 56,
              marginBottom: 16,
              borderRadius: 8,
              backgroundColor: '#f4f4f4',
            }
          }
        >
          <img
            src={add_btn_gray}
            style={{ width: 20, height: 20 }}
            alt="plus_icon"
          />
        </Flex>
        <H6 type="Bold" color={colors.brownish_grey}>
          아티스트 추가
        </H6>
      </Flex>
    </Flex>
  )
}

const CompanyTemplate = ({
  user,
  artistCompanyConnectionList,
  createConnectionsCompanies,
  fetchArtistDetail,
  artistViewMode,
  tempDeleteCompanyIdList,
  isUpdate,
  setIsUpdate,
  isChange,
  setIsChange,
  _removeConnections,
  setArtistViewMode,
}) => {
  const { isShowing, toggle } = ConnectCompanyArtistModal.useModal()

  const containerRef = useRef(null) // 아티스트 리스트 컨테이너 ref
  const [isOverflow, setIsOverflow] = useState(false) // 더보기 버튼 노출 여부
  const [isExpanded, setIsExpanded] = useState(false) // 아티스트 리스트 확장 여부

  /** 아티스트 검색필터 start */
  const [searchArtistValue, setSearchArtistValue] = useState('')
  const filteredArtistCompanyConnectionList =
    artistCompanyConnectionList.filter(item => {
      if (searchArtistValue === '') return true
      return (
        item.artistInfo?.name
          ?.toUpperCase()
          .includes(searchArtistValue.toUpperCase())
        || item.artistInfo?.nameEng
          ?.toUpperCase()
          .includes(searchArtistValue.toUpperCase())
      )
    }) || []
  /** 아티스트 검색필터 end */

  /** 아티스트 리스트 오버플로우 체크 */
  useEffect(() => {
    const checkOverflow = () => {
      const container = containerRef.current
      if (container) {
        const isOverflowing = container.scrollHeight > container.clientHeight

        setIsOverflow(isOverflowing)
      }
    }

    checkOverflow()

    window.addEventListener('resize', checkOverflow)
    return () => window.removeEventListener('resize', checkOverflow)
  }, [filteredArtistCompanyConnectionList])

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <Flex
      type="column"
      style={{ padding: '56px 0px 56px 50px', width: '100%' }}
    >
      <Flex justify="space-between" style={{ marginBottom: '20px' }}>
        <H3 type="Bold">
          {
            `관리 아티스트 (${(artistCompanyConnectionList
            && artistCompanyConnectionList.length)
            || 0})`
          }
        </H3>
        {
          isUpdate ? (
            <Flex>
              <CompletedBtn
                onClick={
                  () => {
                    _removeConnections()
                    setIsUpdate(true)
                  }
                }
                disabled={!isChange}
              >
                <H6 color={colors.main}>수정 완료</H6>
              </CompletedBtn>
              <HeaderBtn onClick={() => setIsUpdate(false)}>
                <HeaderBtnImg src={modify_img} alt="header_btn" />
                <H6 color={colors.main}>취소</H6>
              </HeaderBtn>
            </Flex>
          ) : (
            <HeaderBtn onClick={() => setIsUpdate(true)}>
              <HeaderBtnImg src={modify_img} alt="header_btn" />
              <H6 color={colors.main}>아티스트 관리</H6>
            </HeaderBtn>
          )
        }
      </Flex>
      <SearchArtistInput
        value={searchArtistValue}
        onChange={e => setSearchArtistValue(e.target.value)}
        placeholder="아티스트를 입력해주세요"
      />
      <ListBox
        ref={containerRef}
        style={
          {
            maxHeight: isExpanded ? 'none' : '835px',
            overflow: 'hidden',
          }
        }
      >
        <AddArtistCard toggle={toggle} />
        {
          filteredArtistCompanyConnectionList
          && filteredArtistCompanyConnectionList.length > 0
          && filteredArtistCompanyConnectionList.map(item => (
            <ConnectionArtistCard
              onClick={
                _id => {
                  artistViewMode(_id)
                  setSearchArtistValue('')
                }
              }
              key={item._id}
              item={item}
              isUpdate={isUpdate}
              tempDeleteCompanyIdList={tempDeleteCompanyIdList}
              setArtistViewMode={setArtistViewMode}
            />
          ))
        }
      </ListBox>
      {
        isOverflow && (
          <Flex style={{ marginTop: isExpanded ? '16px' : 0 }}>
            <MoreBtn onClick={() => toggleExpand()}>
              <H6 color={colors.main}>더 보기</H6>
            </MoreBtn>
          </Flex>
        )
      }
      <ConnectCompanyArtistModal.View
        isShowing={isShowing}
        toggle={toggle}
        userId={user && user._id}
        createConnectionsCompanies={createConnectionsCompanies}
      />
    </Flex>
  )
}

export default CompanyTemplate

const HeaderBtn = styled(Button)`
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 48px;
  margin: 10px;
  border-radius: 25px;
  border: solid 2px ${colors.main};
  background-color: #ffffff;

  ${props =>
    props.disabled
    && css`
      pointer-events: none;
      background-color: #f4f4f4;
      border: solid 2px #f4f4f4;

      h6 {
        color: #949494 !important;
      }
    `}
`
const CompletedBtn = styled(Button)`
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 48px;
  margin: 10px;
  border-radius: 25px;
  border: solid 2px ${colors.main};
  background-color: #242424;

  h6 {
    color: #ffffff !important;
  }

  ${props =>
    props.disabled
    && css`
      pointer-events: none;
      background-color: #f4f4f4;
      border: solid 2px #f4f4f4;

      h6 {
        color: #949494 !important;
      }
    `}
`

const HeaderBtnImg = styled(Image)`
  width: 20px;
  height: 20px;
  margin-right: 6px;
`

const MoreBtn = styled(Button)`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  margin-right: 30px;
  border-radius: 8px;
  border: solid 1px #707070;
  background-color: #ffffff;

  ${props =>
    props.disabled
    && css`
      pointer-events: none;
      background-color: #f4f4f4;
      border: solid 2px #f4f4f4;

      h6 {
        color: #949494 !important;
      }
    `}
`

const ListBox = styled(Flex)`
  flex-wrap: wrap;
  gap: 16px;
`
