import React from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
  BarChart,
  Bar,
  LabelList,
  Cell,
} from 'recharts'
import { Flex, Caption1, Caption2, H5, H4 } from '@components/atoms'
import styled from 'styled-components'
import { convertToPricingComma, insightConverseUnitEng } from '@utils/format'

const ToolTipBox = styled(Flex)`
  width: 280px;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #ffffff;
  gap: 12px;
`

const COLORS = ['#2FBA49', '#66A3FF', '#F29097']

const TrackGenderInfoChart = ({ ageData, genderData }) => {
  const maxIndex = ageData.reduce((maxIdx, current, index2, array) => {
    return Number(current.agePercent) > Number(array[maxIdx].agePercent)
      ? index2
      : maxIdx
  }, 0)

  const parsedGenderData = [
    { gender: '남성', rate: genderData?.male },
    { gender: '여성', rate: genderData?.female },
  ]
  return (
    <div
      style={
        {
          marginTop: '30px',
          borderRadius: '6px',
          boxShadow: '0 4px 10px 0 rgba(36,36,36,0.16)',
          backgroundColor: '#ffffff',
          padding: '48px 20px',
          width: '100%',
          // height: '400px',
          display: 'flex',
          flexDirection: 'column',
        }
      }
    >
      <H4 align={'left'} style={{ marginBottom: '22px', paddingLeft: '70px' }}>
        트랙 감상 비율
      </H4>
      <div style={{ flexDirection: 'row', display: 'flex' }}>
        {
          !ageData ? (
            <Flex
              style={
                {
                  width: '50%',
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }
              }
            >
              <Caption1>No data</Caption1>
            </Flex>
          ) : (
            <ResponsiveContainer width="50%" height={330}>
              <BarChart
                data={ageData}
                margin={
                  {
                    top: 0,
                    right: 90,
                    left: 70,
                  }
                }
                barSize={60}
              >
                <XAxis
                  dataKey="ageRange"
                  unit={'대'}
                  padding={{ left: 10, right: 10 }}
                />
                <YAxis
                  dataKey="agePercent"
                  yAxisId="left"
                  unit={'%'}
                  domain={[0, 100]}
                  hide
                />
                <Tooltip content={<CustomTooltip />} />
                <Legend
                  verticalAlign="top"
                  layout="horizontal"
                  align="left"
                  height="50px"
                  content={props => renderLegend({ ...props, ageData })}
                />
                <Bar
                  dataKey="agePercent"
                  yAxisId="left"
                  name="트랙 감상 비율"
                  fill={COLORS[0]}
                  radius={[4, 4, 0, 0]}
                  maxBarSize={240}
                >
                  {
ageData?.map((entry, index) => {
  return (
    <Cell
      key={`cell-${index}`}
      fill={index === maxIndex ? COLORS[0] : '#C0EAC8'} // 성별에 따라 색상 변경
    />
  )
})
                  }
                  <LabelList
                    dataKey="agePercent"
                    position="top" // 라벨을 막대 위에 표시
                    content={
                      ({ x, y, value }) => (
                        <text
                          x={x + 30}
                          y={y}
                          dy={-16} // 추가적인 Y축 위치 조정
                          textAnchor={'middle'}
                          fill="#242424"
                          fontSize="16px"
                        >
                          {`${value}%`}
                        </text>
                      )
                    }
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          )
        }

        <div style={{ borderLeft: 'solid 1px #d4d4d4', height: '100%' }} />

        {
          !genderData ? (
            <Flex
              style={
                {
                  width: '50%',
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }
              }
            >
              <Caption1>No data</Caption1>
            </Flex>
          ) : (
            <ResponsiveContainer width="50%" height={330}>
              <BarChart
                data={parsedGenderData}
                margin={
                  {
                    top: 0,
                    right: 90,
                    left: 70,
                  }
                }
                barSize={60} // 막대 너비
              >
                <XAxis dataKey="gender" padding={{ left: 10, right: 10 }} />
                <YAxis
                  dataKey="rate"
                  yAxisId="left"
                  unit={'%'}
                  domain={[0, 100]}
                  hide
                />
                <Tooltip content={<CustomGenderTooltip />} />
                <Legend
                  verticalAlign="top"
                  layout="horizontal"
                  align="left"
                  height="50px"
                  content={props => renderGenderLegend({ ...props, genderData })}
                />
                <Bar
                  dataKey="rate"
                  yAxisId="left"
                  name="트랙 감상 비율"
                  radius={[4, 4, 0, 0]}
                  maxBarSize={240}
                >
                  {
                    parsedGenderData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={entry.gender === '남성' ? COLORS[1] : COLORS[2]} // 성별에 따라 색상 변경
                      />
                    ))
                  }
                  <LabelList
                    dataKey="rate"
                    position="top" // 라벨을 막대 위에 표시
                    content={
                      ({ x, y, value }) => (
                        <text
                          x={x + 30}
                          y={y}
                          dy={-16} // 추가적인 Y축 위치 조정
                          textAnchor={'middle'}
                          fill="#242424"
                          fontSize="16px"
                        >
                          {`${value}%`}
                        </text>
                      )
                    }
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          )
        }
      </div>
    </div>
  )
}

export default TrackGenderInfoChart

const renderLegend = props => {
  const { payload, ageData } = props
  const maxIndex = ageData.reduce((maxIdx, current, index2, array) => {
    return Number(current.agePercent) > Number(array[maxIdx].agePercent)
      ? index2
      : maxIdx
  }, 0)

  return (
    <div style={{ marginBottom: 200 }}>
      <H5 align={'left'} color={'#949494'}>{`주 연령`}</H5>
      <H4 align={'left'}>{`${ageData[maxIndex]?.ageRange}대`}</H4>
    </div>
  )
}
const renderGenderLegend = props => {
  const { payload, genderData } = props

  return (
    <div style={{ marginBottom: 20 }}>
      <H5 align={'left'} color={'#949494'}>{`성별`}</H5>
      <Flex style={{ gap: '32px' }}>
        <H4 align={'left'} style={{ whiteSpace: 'pre-wrap' }}>
          남성
          <span style={{ color: '#646464' }}>{`  ${genderData?.male}%`}</span>
        </H4>
        <H4 align={'left'} style={{ whiteSpace: 'pre-wrap' }}>
          여성
          <span style={{ color: '#646464' }}>{`  ${genderData?.female}%`}</span>
        </H4>
      </Flex>
    </div>
  )
}

const CustomTooltip = ({ active, payload, label: _label }) => {
  const item = payload && payload[0] && payload[0].payload && payload[0].payload
  const label = `${_label}`

  if (active) {
    return (
      <ToolTipBox type="column">
        <Caption2
          type="Bold"
          align="left"
          color="#646464"
          style={{ fontSize: 10 }}
        >
          {`감상 비율`}
        </Caption2>
        <Flex justify="space-between">
          <Flex align="center" style={{ gap: 4 }}>
            <div
              style={
                {
                  display: 'inline-block',
                  width: 6,
                  height: 6,
                  borderRadius: '50%',
                  backgroundColor: COLORS[0],
                }
              }
            />
            <Caption2>{`${label}대`}</Caption2>
          </Flex>
          <Caption2 align="left">{`${item && item.agePercent}%`}</Caption2>
        </Flex>
      </ToolTipBox>
    )
  }
  return null
}

const CustomGenderTooltip = ({ active, payload, label: _label }) => {
  const item = payload && payload[0] && payload[0].payload && payload[0].payload

  const label = `${_label}`

  if (active) {
    return (
      <ToolTipBox type="column">
        <Caption2
          type="Bold"
          align="left"
          color="#646464"
          style={{ fontSize: 10 }}
        >
          {`감상 비율`}
        </Caption2>
        <Flex justify="space-between">
          <Flex align="center" style={{ gap: 4 }}>
            <div
              style={
                {
                  display: 'inline-block',
                  width: 6,
                  height: 6,
                  borderRadius: '50%',
                  backgroundColor: label === '남성' ? COLORS[1] : COLORS[2],
                }
              }
            />
            <Caption2>{`${label}`}</Caption2>
          </Flex>
          <Caption2 align="left">{`${item && item.rate}%`}</Caption2>
        </Flex>
      </ToolTipBox>
    )
  }
  return null
}
